<template>
  <nav class="bg-white dark:bg-gray-900 sticky w-full top-0 left-0 border-b border-gray-200 dark:border-gray-600 z-40">
    <div class="max-w-screen-xl flex items-center justify-between mx-auto sm:p-1 p-4">
      <a href="/" class="flex items-center">
        <img src="@/assets/logo.png" class="max-h-16 sm:mr-3 pr-2 sm:pr-8" alt="Present Planner Logo" />
      </a>

      <div class="flex items-center md:order-2 sm:px-2 md:px-4 gap-1 md:gap-4">
        <button
v-if="isLoggedIn" @click="signOut" type="button"
          class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg md:text-sm sm:text-xs px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-red-600 dark:focus:ring-red-800"
>
          Logout
        </button>
        <a v-if="!isLoggedIn" href="/register">
          <button
type="button"
            class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg md:text-sm text-xs px-4 py-2 text-center whitespace-nowrap min-w-[100px] dark:bg-red-600 dark:hover:bg-red-600 dark:focus:ring-red-800"
>
            Get started
          </button>
        </a>
        <a v-if="!isLoggedIn" href="/login">
          <button
type="button"
            class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg md:text-sm text-xs px-4 py-2 text-center dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-800"
>
            Login
          </button>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { Auth } from "aws-amplify";

import { mapGetters } from "vuex";

export default {
  name: "Header",
  components: {},
  props: {
    title: {
      type: String,
      default: "Present Planner",
    },
    page: {
      type: String,
      default: "Dashboard",
    },
  },
  data() {
    return {
      deferredPrompt: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    isLoggedIn() {
      return this.$store.state.user.loggedIn;
    },
    userDisplayName() {
      if (this.$store.state.user.loggedIn) {
        try {
          return this.$store.state.user.data.email;
        } catch {
          return "there";
        }
      } else {
        return "there";
      }
    },
  },
  mounted() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });

  },
  methods: {
    signOut() {
      Auth.signOut()
        .then((user) => {
          this.$store.dispatch("fetchUser");
          this.$store.dispatch("resetState");
          this.$router.push("/");
        })
        .catch((error) => console.log(`Error: ${error}`));
    },
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }

  },
};
</script>
